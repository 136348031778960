import SEO from "@/components/core/Seo";
import { HOMEPAGE } from "@/constants";
import { Box } from "@biom3/react";
import { useRive } from "@rive-app/react-canvas";
import { useRouter } from "next/router";
import { useEffect } from "react";

// TODO: Use this as Overview page for future slice
// For now, we just redirect to Games for current slice
export default function Home() {
  const router = useRouter();

  const { RiveComponent } = useRive({
    src: "https://biome.immutable.com/hosted-assets/rive/immutable_loader.riv",
    autoplay: true,
  });

  useEffect(() => {
    router.replace({ pathname: HOMEPAGE, query: router.query });
  }, [router]);

  return (
    <>
      <SEO
        description="Immutable Play is your gateway to Web3 gaming, where you can discover new games, join wishlists for upcoming titles, fund your Immutable Passport wallet, and manage crypto assets. Earn rewards for completing game quests and daily engagement."
        title="Immutable Play"
        url="https://play.immutable.com/"
      />
      <Box
        sx={{
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage: "url(/login_background.webp)",
          textAlign: "center",
          d: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Box
          testId="login-loading"
          sx={{
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "base.spacing.x4",
          }}
        >
          <Box
            id="logo"
            testId="logo-loading"
            sx={{
              gridArea: "logo",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              marginBottom: "base.spacing.x8",
            }}
          >
            <Box
              sx={{ width: "90px", height: "90px" }}
              rc={<RiveComponent />}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
